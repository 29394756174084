<template>
  <ValidationObserver ref="editProfile">
    <form @submit.prevent="onSubmitCustomer">
      <FormErrorAlert
        v-if="profileErrors.length > 0"
        :errors="profileErrors"
        :formRef="this.$refs.editProfile"
      />
      <div class="card">
        <div class="card-header">
          <div class="card-title mb-0">
            Account Information
          </div>
        </div>
        <div class="card-body">
          <div v-if="loading" class="my-3 text-center">
            <span
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
          </div>
          <div v-else class="row">
            <div class="col-12">
              <TextInput
                class="mb-2"
                name="Username"
                id="profileUsername"
                v-model="user.username"
                :readonly="true"
              />
            </div>
            <div class="col-12">
              <TextInput
                class="mb-2"
                name="Email"
                id="profileEmail"
                v-model="user.email"
                :readonly="true"
              />
            </div>
            <div class="col-12">
              <label for="userRole">Admin User Role</label>
              <select
                class="form-control form-control-lg"
                id="userRole"
                v-model="user.userRoleId"
                disabled
              >
                <option
                  v-for="role in userRoles"
                  :key="role.id"
                  :value="role.id"
                  >{{ role.name }}</option
                >
              </select>
            </div>
            <div class="col-12 mt-2">
              <label for="associatedParks">
                Associated Park(s)
              </label>
              <multiselect
                v-model="associatedParks"
                label="longName"
                track-by="id"
                :multiple="true"
                id="associatedParks"
                :clear-on-select="false"
                :preserve-search="true"
                open-direction="bottom"
                placeholder="None"
                :options="locations"
                :disabled="true"
              >
                <template slot="tag" slot-scope="{ option, remove }">
                  <span class="multiselect__tag" :key="option.id">
                    <span>{{ option.longName }}</span>
                    <i
                      tabindex="1"
                      @keypress.enter.prevent="remove(option)"
                      @mousedown.prevent="remove(option)"
                      class="multiselect__tag-icon"
                    ></i>
                  </span>
                </template>
              </multiselect>
            </div>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-header">
          <div class="card-title mb-0">Personal Information</div>
        </div>
        <div class="card-body">
          <div v-if="loading" class="my-3 text-center">
            <span
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
          </div>
          <div v-else class="row">
            <div class="col-12">
              <TextInput
                class="mb-2"
                rules="required|customerName|max:30"
                name="First Name"
                id="profileFirstName"
                placeholder="Enter your first name"
                v-model="user.firstName"
              />
              <TextInput
                class="mb-2"
                rules="required|customerName|max:30"
                name="Last Name"
                id="profileLastName"
                placeholder="Enter your last name"
                v-model="user.lastName"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-header">
          <div class="card-title mb-0">Personal Settings</div>
        </div>
        <div class="card-body">
          <div v-if="loading" class="my-3 text-center">
            <span
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
          </div>
          <div v-else class="row">
            <div class="col-12">
              <label
                for="receiveReservationEmails"
                class="mr-2 float-left pt-1"
              >
                <input
                  type="checkbox"
                  :value="user.receiveReservationEmails"
                  id="receiveReservationEmails"
                  v-model="user.receiveReservationEmails"
                  class="mr-1"
                />
                Receive Reservation Emails</label
              >
            </div>
          </div>
        </div>
      </div>
      <button type="submit" class="btn btn-lg btn-primary mt-2">
        Save Changes
      </button>
    </form>
  </ValidationObserver>
</template>

<script>
import AdminUserService from "@/services/admin/AdminUserService.js";
import AdminLocationService from "@/services/admin/AdminLocationService.js";
import FormErrorAlert from "@/components/alert/FormErrorAlert.vue";
import TextInput from "@/validation/TextInput.vue";
import { ValidationObserver } from "vee-validate";
import Multiselect from "vue-multiselect";
export default {
  name: "AdminUserProfileForm",
  components: {
    TextInput,
    ValidationObserver,
    FormErrorAlert,
    Multiselect
  },
  props: {
    tenantId: String,
    username: String,
    user: Object,
    loading: Boolean
  },
  data() {
    return {
      profileErrors: [],
      userRoles: [],
      locations: []
    };
  },
  methods: {
    async onSubmitCustomer() {
      this.$refs.editProfile.validate().then(async success => {
        if (!success) {
          setTimeout(() => {
            const errors = Object.entries(this.$refs.editProfile.errors)
              .map(([key, value]) => ({ key, value }))
              .filter(error => error["value"].length);
            this.profileErrors = errors;
            this.$refs.editProfile.refs[errors[0]["key"]].$el.scrollIntoView({
              behavior: "smooth",
              block: "center"
            });
          }, 100);
        } else {
          this.errors = [];
          try {
            this.$store.commit("auth/setLoading", true);
            const profileService = new AdminUserService(this.tenantId);
            const response = await profileService.updateUser(this.user);
            if (response.statusCode === "Success") {
              this.$store.commit("alert/setErrorAlert", {
                type: "alert-success",
                message: `Your profile was successfully updated`,
                layer: "admin"
              });
            } else {
              this.$store.commit("alert/setErrorAlert", {
                type: "alert-danger",
                message: "Error in updating your profile, please try again",
                layer: "admin"
              });
            }
          } catch (err) {
            this.$store.commit("alert/setErrorAlert", {
              type: "alert-danger",
              message: "Something went wrong...",
              layer: "admin"
            });
          } finally {
            this.$store.commit("auth/setLoading", false);
          }
        }
      });
    },
    async initialize() {
      const service = new AdminLocationService(this.tenantId);
      const userService = new AdminUserService(this.tenantId);
      const locations = service.getLocations();
      const roles = userService.getUserRoles();
      await Promise.allSettled([locations, roles]).then(responses => {
        if (
          responses[0].value.statusCode === "Success" &&
          responses[1].value.length > 0
        ) {
          this.locations = responses[0].value.data;
          this.userRoles = responses[1].value;
        } else {
          this.$store.commit("alert/setErrorAlert", {
            type: "alert-danger",
            message: "Something went wrong...",
            layer: "admin"
          });
        }
      });
    }
  },
  computed: {
    associatedParks() {
      return this.locations.filter(x =>
        this.user.locations.map(y => y.locationId).includes(x.id)
      );
    }
  },
  created() {
    this.initialize();
  }
};
</script>
