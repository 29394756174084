<template>
  <div class="my-3">
    <div class="row">
      <div class="col-md-3 col-xl-2 no-print">
        <div class="card">
          <div class="card-header">
            <div class="card-title mb-0">Profile Settings</div>
          </div>

          <div class="list-group list-group-flush" role="tablist">
            <a
              class="list-group-item list-group-item-action active"
              data-toggle="list"
              id="accountTab"
              href="#account"
              role="tab"
              >Account</a
            >
            <a
              class="list-group-item list-group-item-action"
              data-toggle="list"
              id="passwordTab"
              href="#password"
              role="tab"
              >Password</a
            >
            <a
              class="list-group-item list-group-item-action"
              data-toggle="list"
              href="#!"
              role="tab"
              @click="goHome"
              >Back To Admin Homepage</a
            >
          </div>
        </div>
      </div>
      <div class="col-md-9 col-xl-10 minHeight">
        <div class="tab-content mb-0 pb-0">
          <div class="tab-pane fade show active" id="account" role="tabpanel">
            <AdminUserProfileForm
              :username="username"
              :tenantId="tenantId"
              :user="user"
              :loading="profileLoading"
            />
          </div>
          <div class="tab-pane fade" id="password" role="tabpanel">
            <AdminProfilePasswordChangeForm
              :externalUserId="externalUserId"
              :tenantId="tenantId"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AdminUserProfileForm from "@/components/admin/AdminUserProfileForm.vue";
import AdminProfilePasswordChangeForm from "@/components/admin/AdminProfilePasswordChangeForm.vue";
import AdminUserService from "@/services/admin/AdminUserService.js";
export default {
  name: "AdminUserProfile",
  title: "Admin - Admin User Profile",
  components: {
    AdminUserProfileForm,
    AdminProfilePasswordChangeForm
  },
  data() {
    return {
      user: {},
      profileLoading: true
    };
  },
  computed: {
    tenantId() {
      return this.$store.getters["tenant/tenantId"];
    },
    username() {
      return this.$store.getters["auth/username"];
    },
    externalUserId() {
      return this.$store.getters["auth/externalUserId"];
    }
  },
  methods: {
    async loadUser() {
      try {
        let userService = new AdminUserService(this.tenantId);
        let user = await userService.getUserByUsername(this.username);
        if (user) {
          user.userLocationIds = user.locations.map(x => x.locationId);
          this.user = user;
        } else {
          this.$store.commit("alert/setErrorAlert", {
            type: "alert-danger",
            message: "Profile could not be loaded, please refresh",
            layer: "public"
          });
        }
      } catch (err) {
        this.$store.commit("alert/setErrorAlert", {
          type: "alert-danger",
          message: "Something went wrong...",
          layer: "public"
        });
      } finally {
        this.profileLoading = false;
      }
    },
    goHome() {
      this.$router.push("/admin").catch(() => {});
    }
  },
  mounted() {
    this.loadUser();
  }
};
</script>

<style scoped>
.tab-content {
  border: none !important;
  background: none !important;
}
.tab-pane {
  background: none !important;
}
.card {
  border: 1px solid #ccc !important;
}
.list-group-item.active {
  background-color: #0f78a8 !important;
  border-color: #0f78a8 !important;
}
.minHeight {
  min-height: 510px;
}
@media print {
  .no-print,
  .no-print * {
    display: none !important;
  }
}
</style>
